import React from 'react';
import './ZipCodeInput.css';

export function ZipCodeInput({ zipCode, setZipCode, onZipSubmit }) {
  return (
    <div className="zip-code-input">
      <h3>Enter Your ZIP Code</h3>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onZipSubmit(zipCode); // Call the onZipSubmit callback
        }}
      >
        <input
          type="text"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)} // Update the zipCode state
          placeholder="Enter ZIP code"
        />
        <button type="submit">Check Distance</button>
      </form>
    </div>
  );
}
