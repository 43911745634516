import React from 'react';
import './CareerSuggestions.css';

export function CareerSuggestions({ careerSuggestions, onCareerClick }) {
  if (!Array.isArray(careerSuggestions) || careerSuggestions.length === 0) {
    return <p>No career suggestions available. Please check back later.</p>;
  }

  return (
    <div className="career-suggestions">
      <h1>Career Suggestions</h1>
      <div className="career-list">
        {careerSuggestions.map((career, index) => (
          <div
            key={index}
            className="career-item"
            onClick={() => onCareerClick(career)}
          >
            <h3>{career.title}</h3>
            <p><strong>Match Score:</strong> {career.fit}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
