import React from 'react';
import { useNavigate } from 'react-router-dom';
import './GettingStarted.css';

function GettingStarted() {
  const navigate = useNavigate();

  const handleStartInventory = () => {
    navigate('/interest-inventory'); // Navigate to InterestInventory when the user clicks "Start Inventory"
  };

  return (
    <div className="getting-started-container">
      <h1>Welcome to AptivaAI</h1>
      <p>Let’s start by getting to know you better. Completing the steps below will help us tailor career recommendations based on your interests.</p>
      
      <div className="steps">
        <div className="step">
          <span className="step-icon">🎯</span>
          <div>
            <h2>Step 1: Complete the O*Net Interest Inventory</h2>
            <p>Discover your career interests by taking the O*Net inventory. This will help us suggest personalized career paths for you.</p>
            {/* Start Inventory button */}
            <button className="button-link" onClick={handleStartInventory}>Start Inventory</button>
          </div>
        </div>
        
        <div className="step">
          <span className="step-icon">📄</span>
          <div>
            <h2>Step 2: Set Up Your Profile</h2>
            <p>Add details like your skills, education, and experience to further personalize your recommendations.</p>
            <button className="button-link" onClick={() => navigate('/profile')}>Go to Profile</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GettingStarted;
