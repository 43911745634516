import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import GettingStarted from './components/GettingStarted.js';
import SignIn from './components/SignIn.js';
import SignUp from './components/SignUp.js';
import InterestInventory from './components/InterestInventory.js';
import Dashboard from './components/Dashboard.js';
import UserProfile from './components/UserProfile.js';
import './App.css';

function App() {
  console.log("App.js is rendering!");
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    console.log("Checking localStorage for authentication...");
    return !!localStorage.getItem('token'); // Return true if token exists
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("App.js useEffect triggered for token validation.");
    const token = localStorage.getItem('token');
    if (token && !isAuthenticated) {
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator
  }

  return (
    <div className="App">
      <h1>AptivaAI - Career Guidance Platform (beta)</h1>
      <Routes>
        {/* Redirect root to /signin */}
        <Route path="/" element={<Navigate to="/signin" />} />

        {/* Public routes */}
        <Route path="/signin" element={<SignIn setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/signup" element={<SignUp />} />

        {/* Protected routes */}
        <Route
          path="/getting-started"
          element={isAuthenticated ? <GettingStarted /> : <Navigate to="/signin" />}
        />
        <Route
          path="/interest-inventory"
          element={isAuthenticated ? <InterestInventory /> : <Navigate to="/signin" />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/signin" />}
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <UserProfile /> : <Navigate to="/signin" />}
        />

        {/* Catch-all for unknown routes */}
        <Route path="*" element={<Navigate to="/signin" />} />
      </Routes>
    </div>
  );
}

export default App;
