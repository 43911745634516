// components/SignUp.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';

function SignUp() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleSignUp = async (event) => {
    event.preventDefault();

    if (!username || !password) {
      setError('Please enter a username and password');
      return;
    }

    try {
      // Call the /api/register endpoint
      const response = await fetch('/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: Math.floor(Math.random() * 10000000), // Generate a temporary user ID (replace with actual logic)
          username,
          password,
        }),
      });

      if (response.ok) {
        setSuccess(true);
        console.log('User registered successfully');
        // Redirect to GettingStarted after successful sign-up
        navigate('/getting-started');
      } else {
        const data = await response.json();
        setError(data.error || 'Failed to register user');
      }
    } catch (err) {
      console.error('Error during registration:', err.message);
      setError('An error occurred while registering. Please try again.');
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-form">
        <h1>Sign Up</h1>
        {error && <p className="error-message">{error}</p>}
        {success && <p className="success-message">Registration successful!</p>}
        <form onSubmit={handleSignUp}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="signup-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-input"
          />
          <button type="submit" className="signup-button">
            Sign Up
          </button>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
