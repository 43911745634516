import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import './Dashboard.css';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { debounce } from 'lodash';
import EconomicProjections from './EconomicProjections.js'; // Import the EconomicProjections component
import {CareerSuggestions} from './CareerSuggestions.js';
import {ZipCodeInput} from './ZipCodeInput.js';
import { ClipLoader } from 'react-spinners';

// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();


  const token = localStorage.getItem('token');
  console.log('Initialized Token:', token);
  // Retrieve career suggestions and RIASEC scores from location state
  const careerSuggestions = location.state?.careerSuggestions || [];
  const riaSecScores = location.state?.riaSecScores || [];
  const [tuitionData, setTuitionData] = useState([]); // Add this to manage tuition data
  const [loadingSchools, setLoadingSchools] = useState(false); // New state for school fetching
  const [calculatingDistances, setCalculatingDistances] = useState({}); // Tracks which schools are being calculated
  const [selectedAreas, setSelectedAreas] = useState(null);
  const [salaryData, setSalaryData] = useState(null);


  useEffect(() => {
    console.log('Received career suggestions:', careerSuggestions);
    console.log('Received RIASEC scores:', riaSecScores);
  }, []); // Empty dependency array ensures this runs only once
  
  
  // Example Axios instance with CORS and security headers
const axiosInstance = axios.create({
  baseURL: 'https://dev.aptivaai.com/api/', // Base URL for the server
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*', // Allow all origins for development (update for production)
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization, Content-Type',
  },
  withCredentials: true, // Include credentials if required
});

const fetchWithSecurityHeaders = async (url, options = {}) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*', // Adjust for production
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS',
  };

  const response = await fetchWithSecurityHeaders(url, {
    ...options,
    headers: {
      ...defaultHeaders,
      ...(options.headers || {}),
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

  const [selectedCareer, setSelectedCareer] = useState(null);  // Store the entire clicked career object
  const [economicProjections, setEconomicProjections] = useState(null);  // Store economic projections data
  const [loading, setLoading] = useState(false); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [schools, setSchools] = useState([]); // Store the filtered list of schools
  const [zipCode, setZipCode] = useState('');  // Store the ZIP code input
  const [zipCodeInput, setZipCodeInput] = useState(''); // Tracks user input in real-time
  const [activeZipCode, setActiveZipCode] = useState(''); // Tracks ZIP code used for calculations
  const [distances, setDistances] = useState({}); // Store distances to each institution
  const [zipCodeReady, setZipCodeReady] = useState(false); // Track if ZIP code is ready for distance calculation

  const riaSecData = riaSecScores.length > 0
    ? riaSecScores.map(score => score.score) // Extract scores for the chart
    : [];

  const riaSecLabels = riaSecScores.length > 0
    ? riaSecScores.map(score => score.area) // Use areas like 'Realistic', 'Investigative', etc. for chart labels
    : [];

    // Fetch schools offering the program for the given CIP Code and user's state
const fetchSchools = async (cipCode, userState) => {
  try {
    setLoadingSchools(true); // Set loading state to true before fetching

    // Step 1: Fetch school data
    const response = await axios.get('/api/CIP_institution_mapping_fixed.json', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch schools: ${response.status}`);
    }

    const schoolsData = response.data;
    console.log('Schools data fetched:', schoolsData); // Log the fetched data to verify

    // Step 2: Clean the CIP Code (remove decimal point if it exists)
    const cleanedCIPCode = cipCode.replace('.', '');
    console.log('Cleaned CIP Code:', cleanedCIPCode); // Log cleaned CIP code

    // Step 3: Filter the schools by CIP Code and user state
    const filteredSchools = schoolsData.filter((school) => {
      const schoolCIPCode = school['CIP Code'].toString().replace('.', ''); // Clean school CIP code

      return (
        schoolCIPCode === cleanedCIPCode &&
        school['State'] === userState // Match CIP Code and state
      );
    });

    console.log('Filtered Schools:', filteredSchools); // Log filtered schools
    setSchools(filteredSchools); // Update state with filtered schools

    // Step 4: Fetch tuition data for the CIP Code and user's state
    const tuitionResponse = await axios.get(`/api/tuition/${cleanedCIPCode}`, {
      params: { state: userState },
    });

    if (tuitionResponse.status !== 200) {
      throw new Error(`Failed to fetch tuition data: ${tuitionResponse.status}`);
    }

    const tuitionData = tuitionResponse.data;
    console.log('Tuition data fetched:', tuitionData);

    // Step 5: Map tuition data to the filtered schools
    const schoolsWithTuition = filteredSchools.map((school) => {
      const matchingTuition = tuitionData.find(
        (tuition) =>
          tuition['school.name']?.toLowerCase().trim() ===
          school['Institution Name']?.toLowerCase().trim()
      );

      return {
        ...school,
        inStateTuition: matchingTuition?.['latest.cost.tuition.in_state'] || 'N/A',
        outOfStateTuition: matchingTuition?.['latest.cost.tuition.out_of_state'] || 'N/A',
      };
    });

    console.log('Schools with Tuition:', schoolsWithTuition); // Log schools with tuition data
    setSchools(schoolsWithTuition); // Update state with schools including tuition data
  } catch (error) {
    console.error('Error fetching schools:', error.message);
    setError('Failed to load schools data');
  } finally {
    setLoadingSchools(false); // Reset loading state
  }
};


  // Prepare the data for the bar chart
  const chartData = {
    labels: riaSecLabels,
    datasets: [
      {
        label: 'RIASEC Scores',
        data: riaSecData,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'RIASEC Results',
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const handleSignOut = () => {
    localStorage.clear();
    navigate('/signin', { replace: true });
  };

    // Function to fetch economic projections for selected career from the local server
const fetchEconomicProjections = async (socCode) => {
  setLoading(true); // Set loading state to true before the request
  setError(null); // Clear any previous error

  try {
    // Use a separate axios instance or override headers for this request
    const response = await axios.get(`/api/projections/${socCode}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch economic projections: ${response.status}`);
    }

    const projectionsData = response.data;
    console.log(`Economic projections data fetched for SOC Code ${socCode}:`, projectionsData); // Log the fetched data to verify
    setEconomicProjections(projectionsData); // Set the fetched data in state
  } catch (error) {
    console.error('Error fetching economic projections:', error);
    setError('Failed to load economic projections data');
  } finally {
    setLoading(false); // Reset loading state after request completes
  }
};

 // Function to geocode an address
 const geocodeAddress = async (address) => {
  try {
    const response = await axiosInstance.get('/maps/geocode', {
      params: { address },
    });
    const { results } = response.data;

    if (results && results[0]) {
      return results[0].geometry.location; // { lat, lng }
    } else {
      console.error('No geocode results found.');
      return null;
    }
  } catch (error) {
    console.error('Error geocoding address:', error.message);
    return null;
  }
};






// Define state for distance readiness
const [isDistanceReady, setIsDistanceReady] = useState(false); // Track if the distance calculation is ready

// Update the Check Distance button logic
const handleCheckDistance = () => {
  if (!zipCode) {
    console.error("ZIP code is required!");
    return;
  }

  // Only set the flag to true when the button is pressed
  setIsDistanceReady(true);

  // Trigger distance calculation when the button is pressed
  handleZipCodeDistances();
};

// Function to calculate distance using the Distance Matrix API
const calculateDistance = async (origins, destinations) => {
  const distanceUrl = `/api/maps/distance?origins=${encodeURIComponent(origins)}&destinations=${encodeURIComponent(destinations)}`;

  try {
    // Make the API call to your backend
    const response = await axios.get(distanceUrl);
    const { rows } = response.data;

    // Check if the distance calculation was successful
    if (
      rows &&
      rows[0] &&
      rows[0].elements &&
      rows[0].elements[0].status === 'OK'
    ) {
      return rows[0].elements[0].distance.text; // e.g., "10 miles"
    } else {
      console.error('Error calculating distance:', rows[0].elements[0].status);
      return 'Distance unavailable';
    }
  } catch (error) {
    console.error('Error fetching distance data:', error.message);
    return 'Distance unavailable';
  }
};

const fetchSalaryData = async (socCode, area) => {
  try {
    const response = await axios.get(`/api/salary?socCode=${encodeURIComponent(socCode)}&area=${encodeURIComponent(area)}`);
    if (response.status === 200) {
      return response.data; // Salary data
    } else {
      console.error('Failed to fetch salary data:', response.data.error);
      return null;
    }
  } catch (error) {
    console.error('Error fetching salary data:', error.message);
    return null;
  }
};




// Function to calculate distances for all institutions
const handleZipCodeDistances = useCallback(async () => {
  if (!activeZipCode) {
    console.error('Active ZIP Code is not provided');
    return;
  }

  const distancesMap = {};
  const calculatingMap = {};

  for (let school of schools) {
    const institutionLocation = `${school['City']}, ${school['State']} ${school['ZIP']}`;
    console.log('Calculating distance for:', institutionLocation);

    // Mark school as "Calculating"
    calculatingMap[school['UNITID']] = true;
    setCalculatingDistances((prev) => ({ ...prev, ...calculatingMap }));

    try {
      const distance = await calculateDistance(activeZipCode, institutionLocation);
      distancesMap[school['UNITID']] = distance || 'Distance unavailable';
      setDistances((prev) => ({
        ...prev,
        [school['UNITID']]: distance || 'Distance unavailable',
      }));
    } catch (error) {
      console.error('Error calculating distance:', error);
      distancesMap[school['UNITID']] = 'Distance unavailable';
    } finally {
      // Mark school as "not calculating"
      calculatingMap[school['UNITID']] = false;
      setCalculatingDistances((prev) => ({ ...prev, [school['UNITID']]: false }));
    }
  }

  console.log('Updated distances:', distancesMap);
  setDistances(distancesMap);
}, [activeZipCode, schools]);


const handleCareerClick = useCallback(
  debounce(async (career) => {
    console.log('Career clicked:', career);
    

    const socCode = career.code; // Get SOC Code from the 'code' property
    console.log('Selected Career SOC Code:', socCode);

    if (!socCode) {
      console.error('SOC Code is missing or undefined for the selected career');
      setError('SOC Code is missing or undefined for the selected career');
      return;
    }

    try {
      // Fetch user profile to get state and area
      const token = localStorage.getItem('token');
      console.log('Retrieved token in Dashboard:', token);
      if (!token) {
        alert('Session expired. Please log in again.');
        navigate('/signin');
        return;
      }

      const profileResponse = await fetch('/api/user-profile', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`, // Include the token for server validation
        },
      });

      if (!profileResponse.ok) {
        throw new Error('Failed to fetch user profile');
      }

      const profileData = await profileResponse.json();
      const userState = profileData.state; // Extract state from profile
      const userArea = profileData.area; // Extract area from profile
      console.log('User State:', userState, 'User Area:', userArea);

      // Fetch CIP Code for the given SOC Code
      console.log(`Fetching CIP Code for SOC Code: ${socCode}`);
      const cipResponse = await fetch(`https://dev.aptivaai.com/api/cip/${socCode}`);
      const cipData = await cipResponse.json();

      if (cipData.error) {
        console.error('Error fetching CIP Code:', cipData.error);
        setError('Error fetching CIP Code');
        return;
      }

      let cipCode = cipData.cipCode; // Extract the CIP Code
      console.log('Raw CIP Code from response:', cipCode);

      if (!cipCode) {
        console.error('CIP Code not found for the selected career');
        setError('CIP Code not found');
        return;
      }

      // Step 1: Clean the CIP Code (remove the decimal point if it exists)
      cipCode = cipCode.replace('.', '');
      console.log('Cleaned CIP Code:', cipCode);

      // Step 2: Slice the first four digits of the cleaned CIP Code
      const cleanedCipCode = cipCode.slice(0, 4);
      console.log('Final Filtered CIP Code:', cleanedCipCode);

      // Update state with the selected career
      setSelectedCareer(career);

      // Reset projections and schools
      setEconomicProjections(null); // Reset projections if a new career is clicked
      setSchools([]); // Reset schools list

      // Fetch economic projections
      const cleanedSocCode = socCode.split('.')[0]; // Clean SOC code
      console.log('Cleaned SOC Code for economic projections:', cleanedSocCode);
      fetchEconomicProjections(cleanedSocCode);

      // Fetch schools offering programs based on the CIP Code and user state
      console.log('Calling fetchSchools with cleaned CIP Code and user state:', cleanedCipCode, userState);
      fetchSchools(cleanedCipCode, userState);

      // Fetch tuition data
      console.log(`Fetching tuition data for CIP Code: ${cleanedCipCode}`);
      const tuitionResponse = await axios.get(`/api/tuition/${cleanedCipCode}`, {
        params: { state: userState }, // Use userState here
      });

      if (tuitionResponse.status !== 200) {
        throw new Error(`Failed to fetch tuition data: ${tuitionResponse.status}`);
      }

      const tuitionData = tuitionResponse.data;
      console.log('Tuition data fetched:', tuitionData);

      // Update tuition data state
      setTuitionData(tuitionData);

      // Fetch salary data
      console.log('Fetching salary data for SOC Code and User Area:', cleanedSocCode, userArea);
      const salaryResponse = await axios.get(`/api/salary`, {
        params: { socCode: cleanedSocCode, area: userArea },
      });

      if (salaryResponse.status !== 200) {
        throw new Error(`Failed to fetch salary data: ${salaryResponse.status}`);
      }

      const salaryData = salaryResponse.data;
      console.log('Salary data fetched:', salaryData);

      // Update salary data state (add a state for salaryData if it doesn't already exist)
      setSalaryData(salaryData);
    } catch (error) {
      console.error('Error during handleCareerClick execution:', error.message || error);
      setError('Failed to process the selected career');
    }
  }, 500), // 500ms debounce
  [fetchEconomicProjections, fetchSchools]
);


    
  
    return (
      <div className="dashboard">
  
  {/* Render CareerSuggestions */}
  <div className="career-suggestions-container">
  <CareerSuggestions
  careerSuggestions={careerSuggestions} // Pass career suggestions to the component
  selectedCareer={selectedCareer} // Pass the selected career state
  onCareerClick={handleCareerClick} // Use the actual handleCareerClick function
/>

    </div>

        <div className="riasec-scores">
          <h2>RIASEC Scores</h2>
          {riaSecScores.length > 0 ? (
            <div className="chart-container">
              <Bar data={chartData} options={options} />
            </div>
          ) : (
            <p>No RIASEC scores available.</p>
          )}
        </div>
  
  
  {/* ZIP Code Input Form */}
<div className="zip-code-container">
  <ZipCodeInput
    zipCode={zipCodeInput}
    setZipCode={setZipCodeInput}
    onZipSubmit={() => {
      console.log('ZIP Code submitted:', zipCodeInput); // Debug log
      setActiveZipCode(zipCodeInput);
      setZipCodeReady(true);
      handleZipCodeDistances(); // Trigger distance calculation
    }}
  />
</div>


{/* Display the list of schools that match the CIP code */}
{selectedCareer && (
  <div className="schools-list">
    <h2>Schools Offering Programs for {selectedCareer.title}</h2>
    {loadingSchools ? (
      <div className="loading-spinner">
        <ClipLoader size={35} color="#4A90E2" />
        <p>Loading schools in your state. Please wait...</p>
      </div>
    ) : schools.length > 0 ? (
      <ul>
        {schools.map((school, index) => {
          const matchingTuitionData = tuitionData.find(
            (tuition) =>
              tuition['school.name']?.toLowerCase().trim() ===
              school['Institution Name']?.toLowerCase().trim()
          );

          const schoolSalaryData = salaryData; // Access salary data
          const isCalculating = calculatingDistances[school['UNITID']];
          return (
            <li key={index}>
              <strong>{school['Institution Name']}</strong>
              <br />
              Degree Type: {school['CREDDESC'] || 'N/A'}
              <br />
              In-State Tuition: ${matchingTuitionData?.['latest.cost.tuition.in_state'] || 'N/A'}
              <br />
              Out-of-State Tuition: ${matchingTuitionData?.['latest.cost.tuition.out_of_state'] || 'N/A'}
              <br />
              Distance: 
              {isCalculating ? (
                <span className="distance-spinner">
                  <ClipLoader size={15} color="#4A90E2" />
                </span>
              ) : (
                distances[school['UNITID']] || 'N/A'
              )}
              <br />
              Median Salary: ${schoolSalaryData?.A_MEDIAN || 'N/A'}
              <br />
              10th Percentile Salary: ${schoolSalaryData?.A_PCT10 || 'N/A'}
              <br />
              90th Percentile Salary: ${schoolSalaryData?.A_PCT90 || 'N/A'}
            </li>
          );
        })}
      </ul>
    ) : (
      <p>No schools found for the selected program.</p>
    )}
  </div>
)}



  
        {/* Economic Projections data for the selected career */}
        {selectedCareer && economicProjections && (
          <div className="career-details">
            <h2>{selectedCareer.title} - Economic Projections for your state</h2>
            {loading ? (
              <p>Loading projections...</p>
            ) : error ? (
              <p>Unfortunately, no economic projection data is available for this career path.</p>
            ) : economicProjections ? (
              <ul>
                <li>2022 Employment: {economicProjections["2022 Employment"]}</li>
                <li>2032 Employment: {economicProjections["2032 Employment"]}</li>
                <li>Total Change: {economicProjections["Total Change"]}</li>
                <li>Annual Openings: {economicProjections["Annual Openings"]}</li>
                <li>Labor Force Exits: {economicProjections["Labor Force Exits"]}</li>
                <li>Projected Growth: {economicProjections["Projected Growth"]}</li>
              </ul>
            ) : (
              <p>Unfortunately, no economic projection data is available for this career path.</p>
            )}
          </div>
        )}
  
        {/* Sign-out button */}
        <div className="sign-out-container">
          <button onClick={handleSignOut} className="sign-out-btn">Sign Out</button>
        </div>
      </div>
    );
  }
  
  export default Dashboard;
  