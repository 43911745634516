// components/SignIn.js
import axios from 'axios';
import jwt_decode from 'jwt-decode'; // Optional for decoding the JWT
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignIn.css';

function SignIn({ setIsAuthenticated }) {
  console.log('SignIn component loaded');
  console.log('Rendering SignIn component');
  console.log('Authentication state in SignIn:', setIsAuthenticated);

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSignIn = async (event) => {
    event.preventDefault();
    
    setError('');

    if (!username || !password) {
      setError('Please enter both username and password');
      return;
    }
    try {
      // Make a POST request to the backend for authentication
      const response = await fetch('/api/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to sign in');
      }
  
      const data = await response.json();
    console.log('SignIn response data:', data);

    const { token, userId } = data;
    localStorage.setItem('token', token);
    localStorage.setItem('userId', userId);

    console.log('Token and userId saved in localStorage');

    // Call setIsAuthenticated(true) to update the state
    setIsAuthenticated(true);
    
      navigate('/getting-started'); // Redirect to GettingStarted after SignIn
    } catch (error) {
      console.error('Sign-In Error:', error.message);
      setError(error.message); // Display error message to the user
    }
  };
  

  return (
    <div className="signin-container">
      <div className="signin-form">
        <h1>Sign In</h1>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignIn}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="signin-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signin-input"
          />
          <button type="submit" className="signin-button">
            Sign In
          </button>
        </form>
        <p>Don’t have an account? <Link to="/signup">Sign Up</Link></p> {/* Sign-Up Link */}
      </div>
    </div>
  );
}

export default SignIn;
